import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { Home } from "../components/Home"
import TestimonialIndex from "../components/testimonial"
import {
  CardContainer,
  CommonCard,
  CommonTitleH2,
} from "../components/commonFeatureComp"
import BgImgComp from "../components/bgImgComp"
import SEO from "../components/SEO/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import WorkFLow from "../../images/work_flow_web.svg"

const StyledLink = styled(Link)`
  text-decoration: none;
`
const StyledA = styled.a`
  text-decoration: none;
`
const IntergrationHomeContainer = styled.div`
  display: grid;
  grid-template-columns: auto 38% auto;
  grid-column-gap: 6%;
  justify-content: center;
  align-content: center;
  background-color: #f3f4f6;
  padding: 35px 20px;
  margin-top: 80px;
  @media (max-width: 1024px) {
    justify-content: space-evenly;
  }
  @media (max-width: 800px) {
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    justify-content: center;
    grid-template-columns: auto;
    grid-row-gap: 25px;
  }
`
const IntergrationIconContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 12%;
  justify-content: center;
  align-content: center;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`
const IntegrationsIconBox = styled.div`
  display: grid;
  grid-template-columns: 65px;
  grid-template-rows: 65px;
  transform: rotate(45deg);
  border: none;
  background-color: white;
  border-radius: 15%;
  align-content: center;
  justify-content: center;
  @media (max-width: 1450px) {
    grid-template-columns: 55px;
    grid-template-rows: 55px;
  }
  @media (max-width: 500px) {
    margin: 20px 20px;
  }
`
const IntegrationsLogo = styled.div`
  transform: rotate(-45deg);
  padding: 7px;
`
const IntegrationsTextFlex = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    justify-content: center;
  }
`
const IntegrationsText = styled.p`
  color: #3d4d69;
  margin: 0;
  padding: 10px 10px;
  font-size: 18px;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`
const IntegrationLink = styled(StyledLink)`
  color: #33cdd1;
  font-size: 19px;
  font-weight: 500;
  padding: 10px;
  @media (max-width: 1450px) {
    font-size: 16px;
  }
`

const ContentVIdeoLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplate};
  justify-content: space-evenly;
  align-content: center;
  margin: ${props => props.margin && props.margin};
  @media (max-width: 800px) {
    grid-template-columns: 46% 41%;
  }
  @media (max-width: 767px) {
    grid-template-columns: 80%;
    grid-row-gap: 20px;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
    padding: 0 25px;
    margin: 30px 0;
  }
`
const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 80%;
  align-content: center;
  justify-content: center;
  @media (max-width: 1200px) {
    grid-template-columns: auto;
  }
  @media (max-width: 767px) {
    margin-top: 25px;
    grid-template-columns: 60%;
    text-align: center;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
    margin-top: 0px;
  }
`
const ContentH3 = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #2f394e;
  @media (max-width: 575px) {
    font-size: 16px;
  }
`

const ContentP = styled.p`
  font-size: 18px;
  color: #3d4d69;
  margin: 15px 0;
  line-height: 1.5;
  letter-spacing: 0.3px;
  @media (max-width: 575px) {
    font-size: 14px;
    line-height: 1.6;
    margin: 0;
  }
`
const StyledVideo = styled.video`
  cursor: pointer;
  &:focus {
    outline: none;
  }
`
const WorkFLowTitleGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 950px);
  justify-content: center;
  margin-top: 100px;
  @media (max-width: 575px) {
    display: none;
  }
`
const WorkFLowTitle = styled.h2`
  text-align: center;
  font-size: 32px;
  padding: 0 20px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const WorkFLowImageGrid = styled.div`
  display: grid;
  grid-template-columns: 90%;
  justify-content: center;
  @media (max-width: 575px) {
    display: none;
  }
`
const IdealTitleGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  margin-top: 80px;
`
const IdealTitle = styled.h2`
  font-size: 32px;
  color: #2f394e;
  text-align: center;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
const Bottomline = styled.div`
  width: 70px;
  height: 3px;
  border-radius: 10px;
  background-color: #33cdd1;
  margin: 0 auto;
`
const IdealCardContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 90%;
  justify-content: center;
`
const IdealCardFlex = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 30px;
  @media (max-width: 800px) {
    display: grid;
  }
`
const IdealCard = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 15px;
  padding: 7px 20px;
  margin: 20px 15px;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(194, 204, 225, 0.4);
  @media (max-width: 800px) {
    margin: 12px 0;
  }
`
const RoundNumber = styled.div`
  font-size: 16px;
  color: white;
  background-color: #33cbcf;
  border-radius: 50%;
  margin: auto 0;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-content: center;
  padding: 8px 0;
`
const ColoredSpan = styled.span`
  color: #33cdd1;
`

const IntegrationsIcon = props => (
  <IntegrationsIconBox>
    <StyledA target="_blank" rel="noopener noreferrer" href={props.autoResLink}>
      <IntegrationsLogo>{props.autoResImg}</IntegrationsLogo>
    </StyledA>
  </IntegrationsIconBox>
)

export default ({ data }) => {
  const [hovered1, setHovered1] = useState(false)
  const [hovered2, setHovered2] = useState(false)
  const [hovered3, setHovered3] = useState(false)
  const toggleHover1 = value => setHovered1(value)
  const toggleHover2 = value => setHovered2(value)
  const toggleHover3 = value => setHovered3(value)
  return (
    <>
      <SEO
        title="Groupboss: A Facebook Group Lead Generation Software"
        keywords="Lead generation, Facebook Group, Lead generation, Facebook group lead generation, Save Facebook group answers, Facebook group questions
, Groupboss, Social media lead generation"
      />
      <Home
        homeH1={
          <span>
            #1 Facebook Group Lead Generation Tool for{" "}
            <ColoredSpan>Community Leaders</ColoredSpan>
          </span>
        }
        homeText="Generate unlimited free leads from Facebook groups by saving answers to group qualifying questions with a single click only. Run email campaigns and turn your Facebook group into a revenue-generating machine."
        image={
          <GatsbyImage
            image={data.indexHome.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="index home"
          />
        }
        chromeImage={
          <GatsbyImage
            image={data.chromestore_badge.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="Chrome web store"
          />
        }
        homeSectionGrid=""
        homeSectionJustify=""
      />
      <IntergrationHomeContainer>
        <IntegrationsTextFlex>
          <IntegrationsText>Integrates with</IntegrationsText>
        </IntegrationsTextFlex>
        <IntergrationIconContainer>
          <IntegrationsIcon
            autoResLink="https://groupboss.io/help/integrate-groupboss-with-mailchimp/"
            autoResImg={
              <GatsbyImage
                image={data.auto_res_mailchimp.childImageSharp.gatsbyImageData}
                alt="mailchimp"
              />
            }
          />
          <IntegrationsIcon
            autoResLink="https://groupboss.io/help/integrate-groupboss-with-activecampaign/"
            autoResImg={
              <GatsbyImage
                image={data.auto_res_2.childImageSharp.gatsbyImageData}
                alt="active campaign"
              />
            }
          />
          <IntegrationsIcon
            autoResLink="https://groupboss.io/help/integrate-groupboss-with-moosend/"
            autoResImg={
              <GatsbyImage
                image={data.auto_res_3.childImageSharp.gatsbyImageData}
                alt="moosend"
              />
            }
          />
          <IntegrationsIcon
            autoResLink="https://groupboss.io/help/integrate-groupboss-with-snovio/"
            autoResImg={
              <GatsbyImage
                image={data.auto_res_4.childImageSharp.gatsbyImageData}
                alt="snov"
              />
            }
          />
          <IntegrationsIcon
            autoResLink="https://groupboss.io/help/integrate-groupboss-with-sendfox/"
            autoResImg={
              <GatsbyImage
                image={data.auto_res_5.childImageSharp.gatsbyImageData}
                alt="sendfox"
              />
            }
          />
        </IntergrationIconContainer>
        <IntegrationsTextFlex>
          <IntegrationLink to="/integrations">view all </IntegrationLink>
        </IntegrationsTextFlex>
      </IntergrationHomeContainer>

      <IdealTitleGrid>
        <IdealTitle>Groupboss is Ideal & Useful for</IdealTitle>
        <Bottomline />
      </IdealTitleGrid>

      <IdealCardContainerGrid>
        <IdealCardFlex>
          <IdealCard>
            <RoundNumber>1</RoundNumber>
            <ContentP>Coaches who need more clients</ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>2</RoundNumber>
            <ContentP>Content creators who want more exposure</ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>3</RoundNumber>
            <ContentP>
              Trainers and online course producers who want to sell more of
              their courses.
            </ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>4</RoundNumber>
            <ContentP>Consultants of different segments.</ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>5</RoundNumber>
            <ContentP>
              Non-profit projects that need more donations from their groups.
            </ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>6</RoundNumber>
            <ContentP>
              E-commerce stores and businesses that need to create a loyal
              audience.
            </ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>7</RoundNumber>
            <ContentP>
              Stay-home mom who wants a side gig helping others by teaching
              cooking, arts, sewing, creative designs, etc.
            </ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>8</RoundNumber>
            <ContentP>Community manager for products or services.</ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>9</RoundNumber>
            <ContentP>
              Software and support communities to keep the customers informed
              and engaged.
            </ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>10</RoundNumber>
            <ContentP>
              Teachers who want to manage the online classes and study materials
              using Facebook groups.
            </ContentP>
          </IdealCard>
          <IdealCard>
            <RoundNumber>11</RoundNumber>
            <ContentP>
              Any FB group owner and community leader who wants to send
              occasional email updates to the group owners.
            </ContentP>
          </IdealCard>
        </IdealCardFlex>
      </IdealCardContainerGrid>

      <WorkFLowTitleGrid>
        <WorkFLowTitle>
          How Groupboss helps you to{" "}
          <ColoredSpan>grow your business</ColoredSpan>
        </WorkFLowTitle>
      </WorkFLowTitleGrid>
      <WorkFLowImageGrid>
        <img src={WorkFLow} alt="workFLow" />
      </WorkFLowImageGrid>

      <ContentVIdeoLayout gridTemplate="35% 35%">
        {/* <StyledVideo
        width="100%"
        onMouseEnter={() => toggleHover1(true)}
        onMouseLeave={() => toggleHover1(false)}
        controls={hovered1}
        autoPlay
        muted
        loop
      >
        <source src={TestVideo} type="video/mp4" />
      </StyledVideo> */}
        <div>
          <GatsbyImage
            image={data.f_1.childImageSharp.gatsbyImageData}
            alt="feature 1"
          />
        </div>
        <TextContainer>
          <ContentH3>
            Set up Groupboss in 60 seconds-1 Click Extension Installation.
          </ContentH3>
          <ContentP>
            It’s no brainer setup that takes literally 1 min. Easy login and get
            all your Group members' data in a single place with all the history.
            Download and use your DATA as you want.
          </ContentP>
        </TextContainer>
      </ContentVIdeoLayout>

      <ContentVIdeoLayout gridTemplate="41% 35%" margin="100px 0">
        <TextContainer>
          <ContentH3>No more copy-pasting & low level admin tasks</ContentH3>
          <ContentP>
            Groupboss automates your workflow and saves you from copy-pasting
            answers from incoming group members everyday other and low-level
            admin tasks.If you can save 20 min every single day, that's an extra
            10 hours every month. You can use this extra time to focus on your
            business growth. Kudos to Groupboss !!!
          </ContentP>
        </TextContainer>
        <div>
          <GatsbyImage
            image={data.artwork_lazy.childImageSharp.gatsbyImageData}
            alt="artwork lazy"
          />
        </div>
      </ContentVIdeoLayout>

      <ContentVIdeoLayout
        gridTemplate="38% 52%"
        style={{ justifyContent: `end` }}
      >
        <TextContainer>
          <ContentH3>
            Emails & Insights of your incoming group members
          </ContentH3>
          <ContentP>
            You can set up three questions to answer while someone joins your
            group. Groupboss does the heavy lifting work to collect those
            answers including Emails. You can then use it for lead nurturing and
            closing deals.
          </ContentP>
        </TextContainer>
        {/* <StyledVideo
        width="100%"
        onMouseEnter={() => toggleHover2(true)}
        onMouseLeave={() => toggleHover2(false)}
        controls={hovered2}
        autoPlay
        muted
        loop
      >
        <source src={TestVideo} type="video/mp4" />
      </StyledVideo> */}

        <div>
          <GatsbyImage
            image={data.f_2.childImageSharp.gatsbyImageData}
            alt="feature 2"
          />
        </div>
      </ContentVIdeoLayout>

      <ContentVIdeoLayout gridTemplate="35% 35%">
        {/* <StyledVideo
        width="100%"
        onMouseEnter={() => toggleHover3(true)}
        onMouseLeave={() => toggleHover3(false)}
        controls={hovered3}
        autoPlay
        muted
        loop
      >
        <source src={TestVideo} type="video/mp4" />
      </StyledVideo> */}
        <div>
          <GatsbyImage
            image={data.f_3.childImageSharp.gatsbyImageData}
            alt="feature 3"
          />
        </div>
        <TextContainer>
          <ContentH3>
            Lead capture - Lead nurture - Close deals & make $$$$.
          </ContentH3>
          <ContentP>
            Collect unlimited leads from your Facebook group by leveraging the
            simple Facebook group question feature. Set questions that members
            would love to answer. Ask for an email address in any of the
            questions and get those collected with Groupboss.
          </ContentP>
        </TextContainer>
      </ContentVIdeoLayout>

      <ContentVIdeoLayout gridTemplate="35% 42%">
        <TextContainer>
          <ContentH3>
            1 Click Custom & Lookalike Audience - Spend Less Money on FB ads and
            Win Greater Results
          </ContentH3>
          <ContentP>
            Groupboss will create a custom audience from your group members list
            using their names and email addresses. You can then use this as a
            custom audience or create a lookalike audience & advertise on
            Facebook. This reduces costs and serves laser-targeted ads on
            Facebook.
          </ContentP>
        </TextContainer>
        {/* <StyledVideo
        width="100%"
        onMouseEnter={() => toggleHover2(true)}
        onMouseLeave={() => toggleHover2(false)}
        controls={hovered2}
        autoPlay
        muted
        loop
      >
        <source src={TestVideo} type="video/mp4" />
      </StyledVideo> */}

        <div>
          <GatsbyImage
            image={data.f_4.childImageSharp.gatsbyImageData}
            alt="feature 4"
          />
        </div>
      </ContentVIdeoLayout>
      <TestimonialIndex />

      <CardContainer>
        <CommonCard
          cardTextWeight="500"
          cardText="24/7 Support by real people"
          cardPadding="13px 25px"
        />
        <CommonCard
          cardTextWeight="500"
          cardText="100% Secure payment"
          cardPadding="13px 25px"
        />
        <CommonCard
          cardTextWeight="500"
          cardText="Best customer experience"
          cardPadding="13px 25px"
        />
      </CardContainer>

      <BgImgComp />
    </>
  )
}
export const query = graphql`
  {
    indexHome: file(relativePath: { eq: "home.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    chromestore_badge: file(relativePath: { eq: "chromeExtention.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          width: 150
          height: 33
          placeholder: NONE
          layout: FIXED
        )
      }
    }
    auto_res_mailchimp: file(relativePath: { eq: "auto_res_mailchimp.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    auto_res_2: file(relativePath: { eq: "auto_res_2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    auto_res_3: file(relativePath: { eq: "moosend.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    auto_res_4: file(relativePath: { eq: "auto_res_4.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    auto_res_5: file(relativePath: { eq: "sendfox.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    artwork_lazy: file(relativePath: { eq: "artwork_lazy.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    f_1: file(relativePath: { eq: "f_1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    f_2: file(relativePath: { eq: "f_2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    f_3: file(relativePath: { eq: "f_3.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    f_4: file(relativePath: { eq: "target_audience.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`
